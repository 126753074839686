import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { Accordion } from "../../utils/accordion/accordion";
import { Address } from "../address/address";
import { Tooltip, Checkbox, Button } from "@src/components/atoms";
import { Datepicker } from "@src/components/atoms/date-picker/date-picker.component";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { useAppContext } from "@src/context/app-context";
import { useCustomerUpdate } from "@src/hooks";
import { setOrderInfo, updateCustomer } from "@src/context/app-actions";
import {
  calculateMonthsDifference,
  filterCustomerData,
} from "@src/helpers/appHelper";
import { yupResolver } from "@hookform/resolvers/yup";
import { addressValidator } from "@src/validators";
import { useComputedQuotation } from "@src/hooks/useCalculateQuatation";

import "./current-and-past-addresses.scss";

const generateAddress = (type: string, isSame: boolean, address: any) => {
  if (isSame) {
    return { address_type: type, entity_type: "Mailing" };
  }
  return { address_type: type, ...address, entity_type: null };
};
export const CurrentAndPastAddresses = ({
  stepTwoDataReceivedEvent,
}: {
  stepTwoDataReceivedEvent: () => void;
}) => {
  const {
    state: {
      user: { customer },
      order,
      tenant: { min_months_stay_at_address },
      dealer: { dealer_code },
      states,
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { customer_addresses, reference_id } = customer;
  const [showPrevAddress, setPrevAddressVisible] = useState<boolean>(
    !!customer_addresses.previous
  );
  const { control, handleSubmit, watch, setValue } = useForm<any>({
    resolver: yupResolver(addressValidator(showPrevAddress)),
    defaultValues: {
      previous: customer_addresses.previous,
      mailing: customer_addresses.mailing,
      billing: customer_addresses.billing,
      garaging: customer_addresses.garaging,
      billingSameAsMailing: !!customer_addresses.billing?.entity_type,
      garagingSameAsMailing: !!customer_addresses.garaging?.entity_type,
    },
  });

  const { mutate: updateCustomerData, isPending } = useCustomerUpdate({
    reference_id: reference_id,
    dealer_code: dealer_code,
  });
  const { v2Results, setOrderValues, orderValues, setCalculated } =
    useComputedQuotation();

  const billingSameAsMailing = watch("billingSameAsMailing");
  const garagingSameAsMailing = watch("garagingSameAsMailing");
  const garaging = watch("garaging");
  const billing = watch("billing");

  useEffect(() => {
    if (!isEmpty(v2Results)) {
      appDispatch(
        setOrderInfo({
          ...order,
          ...orderValues,
          estimated_monthly_payment: v2Results.monthly_payment,
        })
      );
      setOrderValues(null);
      setCalculated({});
    }
  }, [v2Results, orderValues]);

  useEffect(() => {
    const move_in_date = watch("mailing")?.move_in_date;
    if (move_in_date && min_months_stay_at_address) {
      if (
        calculateMonthsDifference(move_in_date) < min_months_stay_at_address
      ) {
        setPrevAddressVisible(true);
      } else {
        setPrevAddressVisible(false);
      }
    }
  }, [watch("mailing")?.move_in_date]);

  const onSubmit: SubmitHandler<any> = (values) => {
    const customerAddresses = [
      {
        address_type: "Mailing",
        ...values.mailing,
        move_in_date: values.mailing?.move_in_date
          ? dayjs(values.mailing.move_in_date).format("YYYY-MM-DD")
          : null,
      },
      ...(showPrevAddress
        ? [generateAddress("Previous", false, values.previous)]
        : []),

      generateAddress("Billing", billingSameAsMailing, values.billing),
      generateAddress("Garaging", garagingSameAsMailing, values.garaging),
    ];

    updateCustomerData(
      {
        customer_addresses: customerAddresses,
        type: "",
      },
      {
        onSuccess: (customerInfo) => {
          const filterData = filterCustomerData(customerInfo);
          const customerData = { ...customer, ...filterData };
          setOrderValues({
            ...order,
            customerV2Address: garagingSameAsMailing
              ? {
                  ...values.mailing,
                }
              : { ...values.garaging },
          });
          appDispatch(updateCustomer(customerData));
          stepTwoDataReceivedEvent();
        },
        onError: (error) => {
          console.error("Error fetching customer:", error);
        },
      }
    );
  };
  return (
    <div className="checkout">
      <div>
        <div className="checkout-form-header checkout-form-header--padding">
          <h3 className="checkout-title text--subsection-2 text-secondary">
            02
          </h3>
          <h3 className="checkout-title text--subsection-2">Addresses.</h3>
        </div>
        <div>
          <div className="checkout-info-wrapper">
            <p className="checkout-subtitle text--body-2">
              Please provide your current permanent address.
              {/* <i className="checkout-info-icon" />
              <Tooltip /> */}
            </p>
            <p className="checkout-info text--label">
              <i className="checkout-title-icon" />
              If you've lived at this address for less than 11 months, you'll
              need to enter your previous address as well.
            </p>
          </div>
        </div>
      </div>
      <div className="checkout-initial-form-container">
        <form onSubmit={handleSubmit(onSubmit)} className="checkout-form">
          <Address type="mailing" control={control} stateOptions={states} />

          <div className="checkout-form-spacing checkout-column-wrapper">
            <Controller
              name="mailing.move_in_date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Datepicker
                  id="move_in_date"
                  placeholder="Choose"
                  maxDate={dayjs(new Date())}
                  label="Move-in date"
                  error={error}
                  format="MMM DD, YYYY"
                  {...field}
                />
              )}
            />
          </div>

          {/* <AddressTypeSelector /> */}

          {showPrevAddress && (
            <>
              <hr className="checkout-hr" />
              <p className="checkout-subtitle text--body-2">
                Please provide your previous address.
              </p>
              <Address
                type="previous"
                control={control}
                stateOptions={states}
              />
              <div className="checkout-form-spacing checkout-column-wrapper">
                <Controller
                  name="previous.move_in_date"
                  control={control}
                  render={({ field }) => (
                    <Datepicker
                      id="move_in_date"
                      placeholder="Choose"
                      maxDate={dayjs(new Date())}
                      label="Move-in date"
                      format="MMM DD, YYYY"
                      {...field}
                    />
                  )}
                />
              </div>
            </>
          )}

          {!billingSameAsMailing && billing && <hr className="checkout-hr" />}

          {/*<MoveDateAddress />*/}

          {billing && (
            <div className={billingSameAsMailing ? "billing-address-wrap" : ""}>
              <div className="checkout-billing-description">
                Please Provide your billing address
              </div>
              <Controller
                name="billingSameAsMailing"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Checkbox
                    id={"provdeBillingChk"}
                    checked={value}
                    label="Same as current (mailing) address"
                    htmlFor="provdeBillingChk"
                    {...rest}
                  />
                )}
              />
              {!billingSameAsMailing && (
                <Address
                  type="billing"
                  control={control}
                  stateOptions={states}
                />
              )}
            </div>
          )}
          {!garagingSameAsMailing && !billingSameAsMailing && (
            <hr className="checkout-hr" />
          )}

          {garaging && (
            <div className="garging-address-wrap">
              <div className="checkout-billing-description">
                Please Provide your garaging address
              </div>
              <Controller
                name="garagingSameAsMailing"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Checkbox
                    id="provdeMailingChk"
                    label="Same as current (mailing) address"
                    checked={value}
                    htmlFor="provdeMailingChk"
                    {...rest}
                  />
                )}
              />
              {!garagingSameAsMailing && (
                <Address
                  type="garaging"
                  control={control}
                  stateOptions={states}
                />
              )}
            </div>
          )}
          {!(billing || garaging) && (
            <p className="text--body-2 different-billing-garging">
              If your garaging or billing address is different from your
              permanent address, please provide them below.
              <i className="checkout-info-icon" /> <Tooltip />
            </p>
          )}
          <div className="checkout-accordion-container">
            <Accordion
              billingAddress={billing}
              garagingAddress={garaging}
              addBillingAddressHandler={() => {
                setValue("billing", {});
              }}
              addGaragingAddressHandler={() => {
                setValue("garaging", {});
              }}
            />
          </div>

          {/* <SpouseInfoDisclosure control={control} stateOptions={states}/> */}

          <div className="form-wrapper form-wrapper--button-top-spacer">
            <Button
              type="default"
              isLoading={isPending}
              htmlType="submit"
              text="Save & continue"
              fixed
              fullWidth
            />
          </div>
        </form>
      </div>
    </div>
  );
};
