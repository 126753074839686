import { Checkbox } from "@src/components/atoms";

export const LegalDisclaimerContent = ({ setCheckbox }: any) => {
  return (
    <div className="legal-disclaimer-content--wrap">
      <Checkbox
        id={"legalDiscalimerText"}
        label={
          <>
            I have read and agree to the BMW Center{" "}
            <a
              href="/"
              rel="noopener noreferrer"
              className="text-underline fw-normal text-dark "
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Terms
            </a>{" "}
            and{" "}
            <a
              href="/"
              rel="noopener noreferrer"
              className="text-underline fw-normal text-dark"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Privacy Policy
            </a>{" "}
            and BMW of North America, LLC (“BMW NA”) Terms and Privacy Policy. I
            understand and agree that (1) this online process does not guarantee
            the sale or lease of this vehicle to me until terms are agreed and a
            credit application has been reviewed; (2) BMW NA does not sell
            vehicles nor finance or lease vehicles; (3) BMW Center sets actual
            price; (4) a vehicle may not be available for me until a BMW Center
            countersigns a Buyer’s Order and confirms both the availability of
            the vehicle and the purchase or lease offer; (5) circumstances,
            including, without limitation, pricing errors, vehicle damage, or
            unavailability, may require the termination or restart of any
            purchase or leasing transaction.
          </>
        }
        htmlFor="legalDiscalimerText"
        isCompact
        onChange={(value) => {
          setCheckbox(value);
        }}
      />
    </div>
  );
};
