import { camelCase } from "lodash";

export const disclaimerByType = (disclaimers: any, dealerName?: string) => {
  return disclaimers.reduce((acc, disclaimer_needed) => {
    const { disclaimer_name, disclaimer_version, disclaimer_details, ...rest } =
      disclaimer_needed;
    const changesDisclaimer = disclaimer_needed.disclaimer_details?.replace(
      /@dealer/g,
      `${dealerName}`
    );

    if (disclaimer_name) {
      acc[camelCase(disclaimer_name)] = {
        disclaimer_name,
        disclaimer_version,
        customerFetch: true,
        changesDisclaimer,
      };
    }
    return acc;
  }, {});
};

export const getDisclaimerByType = (disclaimers: any, dealerName?: string) => {
  return disclaimers.reduce((acc, disclaimer_needed) => {
    const { version, description, name, ...rest } = disclaimer_needed;
    const changesDisclaimer = disclaimer_needed.description?.replace(
      /@dealer/g,
      `${dealerName}`
    );
    if (name) {
      acc[camelCase(name)] = {
        disclaimer_name: name,
        disclaimer_version: version,
        customerFetch: false,
        changesDisclaimer,
      };
    }
    return acc;
  }, {});
};
