import { http } from "../utilities";
import { DMS_BASE_URL, GET_ALL_DOCUMENT_PACKAGES } from "../constants/routes";

import { UseMutateFunction, useMutation } from "@tanstack/react-query";

export const useGetDocumentPackages = (): {
  mutate: UseMutateFunction<
    any,
    { detail: { message: string } },
    { referenceId; orderId; documentType }
  >;
  error: string | undefined;
} => {
  const { mutate, error } = useMutation<
    any,
    { detail: { message: string } },
    { referenceId; orderId; documentType }
  >({
    mutationFn: async (payload) => {
      const queryParams = `?reference_id=${payload.referenceId}&external_order_id=${payload.orderId}&document_type=${payload.documentType[0]}&document_type=${payload.documentType[1]}`;
      return await http(DMS_BASE_URL)
        .get(GET_ALL_DOCUMENT_PACKAGES + queryParams)
        .then((res) => res.data);
    },
  });
  return {
    mutate,
    error: error && error.detail ? error.detail.message : undefined,
  };
};
// TO BE COMPLETED IN NEXT SPRINT
// export const getPresSignedUrl = async (payload) => {
// 	return http(DMS_BASE_URL)
// 		.get(`${Endpoint.GET_PRE_SIGNED_URL}/${payload.key}`)
// 		.then((res) => {
// 			return res.data
// 		})
// }

// export const signDocumentPackage = async (payload) => {
// 	return http(DMS_BASE_URL)
// 		.post(
// 			`${Endpoint.SIGN_DOCUMENT_BY_ROLE}/${payload?.document_package_identifier}/${payload?.document_identifier}/${payload?.signature_id}/${payload.signer_role}?date_time_format=YYYY%2FMM%2FDD&time_zone=UTC`
// 		)
// 		.then((res) => {
// 			return res.data
// 		})
// }

// export const updateCustomerSignature = async (payload) => {
// 	const query = `reference_id=${payload.reference_id}`
// 	return http(DMS_BASE_URL)
// 		.patch(`${Endpoint.CUSTOMER_SIGNATURE}?${query}`, payload)
// 		.then((res) => {
// 			return res.data
// 		})
// }

// ror: error?.message
