import { useAppContext } from "@src/context/app-context";
import { useCalculateOrderQuotation } from "./program";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { FeeTypes } from "@src/constants/enum";
import { getOrderOptionsPayloadMapping } from "@src/helpers/payloadHelper";

export const useComputedQuotation = () => {
  const [orderValues, setOrderValues] = useState<any>(null);
  const [v2Results, setCalculated] = useState<any>({});
  const { mutate: calculateOrder } = useCalculateOrderQuotation();
  const {
    state: {
      order,
      dealer: { dealer_address, dealer_code },
      lender: { defaultLender },
      user: {
        customer: {
          email,
          customer_addresses: { mailing },
        },
      },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const {
    down_payment,
    dealer_id,
    order_fees,
    selling_price,
    order_tradein,
    contract_term,
    credit_rating,
    rebate,
    base_rate,
    apr,
    net_finance_amount,
    order_fnI,
    order_options,
    order_asset,
    annual_usage,
    rental_mode,
    margin,
    finance_type,
    lender_id,
    rv_balloon_percentage,
  } = order;

  useEffect(() => {
    if (lender_id && email && orderValues && isEmpty(v2Results)) {
      calculateOrder(
        {
          down_payment:
            (selling_price / 100) * (orderValues.down_payment ?? down_payment),
          dealer_code: dealer_code,
          per_unit_selling_price: selling_price,
          fees: order_fees
            ?.filter((fee: any) => fee.event !== FeeTypes.EOT)
            .map((orderFee: any) => ({
              fee_name: orderFee.fee_name,
              fee_amount: orderFee.default_amount,
              fee_handling: orderFee.fee_handling,
              is_taxable: orderFee.taxable,
            })),
          insurance: [],
          trade_in_amount: order_tradein?.trade_in_amount,
          fni_products: order_fnI.map(
            (fni: {
              applied_price: number;
              product_name: string;
              taxable: boolean;
            }) => ({
              fni_product_amount: fni.applied_price,
              fni_product_name: fni.product_name,
              is_taxable: fni.taxable,
            })
          ),
          options: getOrderOptionsPayloadMapping(order_options),
          customer_address: {
            street_address:
              orderValues.customerV2Address?.address_line_1 ??
              mailing.address_line_1,
            city: orderValues.customerV2Address?.city ?? mailing.city,
            state:
              orderValues.customerV2Address?.state_name ?? mailing.state_name,
            zip_code:
              orderValues.customerV2Address?.zip_code ?? mailing.zip_code,
            address_type: "Customer",
          },

          dealer_address: {
            street_address: dealer_address.address_line_1,
            city: dealer_address.city,
            state: dealer_address.state_name,
            zip_code: dealer_address.zip_code,
            address_type: "Dealer",
          },
          vehicle: {
            vin: order_asset.vin,
            is_new: order_asset.type === "New",
            odometer_mileage: order_asset.mileage,
            msrp: order_asset.msrp,
            year: order_asset.year,
            transmission_type: order_asset.transmission_type,
          },
          asset_condition: 63,
          rebate: { price: rebate ?? 0 },
          credit_rating: orderValues.credit_rating ?? credit_rating,
          terms: orderValues.contract_term ?? contract_term,
          annual_usage: orderValues.annual_usage ?? annual_usage,
          is_rv_guaranteed: "False",
          rv_amount: 0,
          net_financed_amount: net_finance_amount,
          base_rate: base_rate,
          apr: orderValues.apr ?? apr,
          finance_type: finance_type,
          interest_chart_margin: margin,
          rental_mode: orderValues.rental_mode ?? rental_mode,
          rental_frequency: "Monthly",
          trade_ins: Array.isArray(order_tradein)
            ? order_tradein
            : order_tradein
            ? [order_tradein]
            : [],
          calculation_method: null,
          rv_value: orderValues.rv_balloon_percentage ?? rv_balloon_percentage,
          lender_id: defaultLender.external_code,
        },
        {
          onSuccess: (data) => {
            setCalculated(data);
            return data;
          },
          onError: (error) => {
            setOrderValues(null);
            console.error("Error fetching pricing:", error);
          },
        }
      );
    }
  }, [orderValues]);

  return {
    v2Results,
    orderValues,
    setCalculated,
    setOrderValues,
  };
};
