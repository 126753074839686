import { memo } from "react";
import { Step } from "@src/components/atoms/step/step.component";
import {
  RadioSelector,
  PersonalDetails,
  CoApplicantDetails,
  ApplicationDetails,
} from "@src/components/molecules";
import { useForm, SubmitHandler } from "react-hook-form";
import { Button, Checkbox, Input, MessageAlert } from "@src/components/atoms";

import "./vehicle-ownership.style.scss";
import { BasicCustomerInfo } from "@src/types/customer";
import { useCustomerUpdate } from "@src/hooks/customer";
import { useAppContext } from "@src/context/app-context";
import { filterCustomerData } from "@src/helpers/appHelper";
import { setOrderInfo, updateCustomer } from "@src/context/app-actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { basicValidator } from "@src/validators";

const VehicleOwnership = memo(
  ({
    stepOneDataReceivedEvent,
    customerInfo,
    dispatch,
    state,
  }: {
    customerInfo: Omit<BasicCustomerInfo, "email"> & { updated_by: string };
    dispatch;
    state;
    stepOneDataReceivedEvent: () => void;
  }) => {
    const {
      control,
      handleSubmit,
      watch,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm<any>({
      resolver: yupResolver(basicValidator),
      defaultValues: { ...customerInfo, vehicle: { ownership: "sole-person" } },
    });

    const { state: appState, dispatch: appDispatch } = useAppContext();
    const { mutate: updateCustomerData, isPending } = useCustomerUpdate({
      reference_id: appState.user.customer.reference_id,
      dealer_code: appState.dealer.dealer_code,
    });

    const onSubmit: SubmitHandler<
      BasicCustomerInfo & { vehicle: { ownership: string } }
    > = (values) => {
      const { vehicle, ...rest } = values;
      rest.mobile_number =
        rest.mobile_number.length < 6 ? "" : rest.mobile_number;
      updateCustomerData(
        {
          customer_profile: rest,
          type: "",
        },
        {
          onSuccess: (customerInfo) => {
            const filterData = filterCustomerData(customerInfo);
            const customerData = { ...appState.user.customer, ...filterData };
            appDispatch(
              setOrderInfo({
                ...appState.order,
                customer_info: { ...appState.order.customer_info, ...rest },
              })
            );
            appDispatch(updateCustomer(customerData));
            stepOneDataReceivedEvent();
          },
          onError: (error) => {
            console.error("Error fetching customer:", error);
            if (error.detail?.message?.includes("ssn")) {
              setError("ssn", {
                type: "manual",
                message: error.detail.message.split(":")[0].trim(),
              });
            }
          },
        }
      );
    };

    return (
      <div className="who-i-am">
        <div className="who-i-am section-wrapper">
          <Step />
          <RadioSelector formControl={control} />
        </div>
        {state.message && watch("vehicle.ownership") === "co-applicant" && (
          <ApplicationDetails
            title="Application details."
            tagLabel="Completed by primary applicant"
            tagLabelType="primary"
            innerHeading="Vehicle ownership."
            innerDescription="This vehicle is for you and Christopher Runnen. You’ll share financial responsibility and ownership of this BMW. "
          />
        )}
        {watch("vehicle.ownership") === "co-applicant" && (
          <CoApplicantDetails state={state} dispatch={dispatch} />
        )}
        {state.message && watch("vehicle.ownership") === "co-applicant" && (
          <MessageAlert
            type="success"
            title="An invitation has been sent to Sarah Runnen."
            subTitle="Please note that both applicants must submit their portion before we can process your joint credit application."
            showCloseButton={false}
          />
        )}
        {watch("vehicle.ownership") === "sole-person" && (
          <PersonalDetails
            formControl={control}
            errors={errors}
            showSSNValue={!!customerInfo.ssn}
            clearErrors={clearErrors}
            isPending={isPending}
            handleSubmit={handleSubmit(onSubmit)}
          />
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if `customerInfo` has changed
    return prevProps.customerInfo === nextProps.customerInfo;
  }
);

export { VehicleOwnership };
