import { ContractPreview } from "@src/components/organisms";
import {
  setOrderInfo,
  updateContractDocuments,
} from "@src/context/app-actions";
import { useAppContext } from "@src/context/app-context";
import { useGetDocumentPackages } from "@src/hooks";
import { BaseLayout } from "@src/layouts";
import { useEffect } from "react";

export const ContractPreviewPage = () => {
  const {
    state: {
      contractDocs,
      order,
      user: { customer },
    },
    dispatch: appDispatch,
  } = useAppContext();

  const { mutate: updateCustomerData } = useGetDocumentPackages();

  useEffect(() => {
    updateCustomerData(
      {
        referenceId: order.reference_id || customer.reference_id,
        orderId: order.reference_number,
        documentType: ["Contract", "General"],
      },
      {
        onSuccess: (response) => {
          appDispatch(updateContractDocuments(response));
        },
        onError: (error) => {
          console.error(error);
        },
      }
    );
  }, []);

  return (
    <BaseLayout hasMenu={true} hasFooter={true}>
      {contractDocs.length > 0 && (
        <ContractPreview contractDocs={contractDocs} />
      )}
    </BaseLayout>
  );
};
