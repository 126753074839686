import { useEffect, useRef, useReducer } from "react";
import { BaseLayout } from "@src/layouts";

import { getDisclaimerByType } from "@src/helpers";

import "./finance-offer-page.scss";
import { useGetDisclaimerByName } from "@src/hooks";
import { updateDisclaimers } from "@src/context/app-actions";

import { Steps } from "@src/components/atoms";
import { VehicleCard, FixedVehicleCard } from "@src/components/molecules";

import {
  SelectOfferCompleted,
  SelectOffer,
} from "@src/components/organisms/credit-application/finance-offers/01-select-offer";
import {
  InsuranceInformation,
  InsuranceInformationCompleted,
} from "@src/components/organisms/credit-application/finance-offers/02-insurance-information";
import {
  BuyersOrderCompleted,
  BuyersOrder,
} from "@src/components/organisms/credit-application/finance-offers/03-buyers-order";
import {
  DepositCompleted,
  Deposit,
} from "@src/components/organisms/credit-application/finance-offers/04-deposit";
import { Col, Row } from "antd";
import { useAppContext } from "@src/context/app-context";

interface initialState {
  index: number;
  isSelectOfferFilled: boolean;
  isInsuranceInfoFilled: boolean;
  isBuyersOrderFilled: boolean;
  isDepositFilled: boolean;
}

const initialState: initialState = {
  index: 0,
  isSelectOfferFilled: false,
  isInsuranceInfoFilled: false,
  isBuyersOrderFilled: false,
  isDepositFilled: false,
};

interface ActionType {
  MOVE_TO_NEXT_STEP: string;
  TOGGLE_SELECTED_OFFER: string;
  TOGGLE_INSURANCE_INFO: string;
  TOGGLE_BUYERS_ORDER: string;
  TOGGLE_DEPOSIT: string;
}

const ACTION_TYPE: ActionType = {
  MOVE_TO_NEXT_STEP: "move to next step",
  TOGGLE_SELECTED_OFFER: "toggle selected offer",
  TOGGLE_INSURANCE_INFO: "toggle insurance info",
  TOGGLE_BUYERS_ORDER: "toggle buyers order",
  TOGGLE_DEPOSIT: "toggle deposit",
};

const reducer = (state: initialState, action: { type: string }) => {
  switch (action.type) {
    case ACTION_TYPE.MOVE_TO_NEXT_STEP:
      if (state.index === 3) {
        return initialState;
      }
      return { ...state, index: state.index + 1 };
    case ACTION_TYPE.TOGGLE_SELECTED_OFFER:
      return { ...state, isSelectOfferFilled: !state.isSelectOfferFilled };
    case ACTION_TYPE.TOGGLE_INSURANCE_INFO:
      return { ...state, isInsuranceInfoFilled: !state.isInsuranceInfoFilled };
    case ACTION_TYPE.TOGGLE_BUYERS_ORDER:
      return { ...state, isBuyersOrderFilled: !state.isBuyersOrderFilled };
    case ACTION_TYPE.TOGGLE_DEPOSIT:
      return { ...state, isDepositFilled: !state.isDepositFilled };
    default:
      return state;
  }
};

const FinanceOfferPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    state: {
      order: { reference_number },
      user: { customer },
    },
    dispatch: appDispatch,
  } = useAppContext();
  const { mutate: changeDisclaimer } = useGetDisclaimerByName();
  useEffect(() => {
    changeDisclaimer(
      { disclaimerName: "acceptance-offer" },
      {
        onSuccess: async (response: any) => {
          if (Array.isArray(response) && response[0]) {
            const changesDisclaimer = getDisclaimerByType(response);
            await appDispatch(updateDisclaimers(changesDisclaimer));
          }
        },
      }
    );
  }, []);
  if (!customer.fetchedData) {
    return null;
  }
  return (
    <BaseLayout>
      <div className="bmw-container">
        <Row gutter={{ xs: 16, sm: 16, md: 24 }}>
          <Col xs={24} sm={24} md={24} lg={16}>
            <div className="address-left-content">
              <p className="menu-title text--body-2 d-md-none d-block">
                Purchase Online
              </p>
              <div className="page-title-wrap">
                <h3 className="text--h3">Select your offer.</h3>
                <p className="text--body-2">
                  Questions or concerns?{" "}
                  <a
                    href="/"
                    rel="noopener noreferrer"
                    className="text-underline fw-normal text-dark "
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Contact your dealer.
                  </a>
                </p>
              </div>
              {state.index >= 0 && !state.isSelectOfferFilled && (
                <SelectOffer
                  handleClick={() => {
                    dispatch({ type: ACTION_TYPE.TOGGLE_SELECTED_OFFER });
                    dispatch({ type: ACTION_TYPE.MOVE_TO_NEXT_STEP });
                  }}
                />
              )}
              {state.isSelectOfferFilled && (
                <SelectOfferCompleted
                  handleClick={() => {
                    dispatch({ type: ACTION_TYPE.TOGGLE_SELECTED_OFFER });
                  }}
                />
              )}

              {state.index >= 1 &&
                !state.isInsuranceInfoFilled &&
                reference_number && (
                  <InsuranceInformation
                    handleClick={() => {
                      dispatch({ type: ACTION_TYPE.TOGGLE_INSURANCE_INFO });
                      dispatch({ type: ACTION_TYPE.MOVE_TO_NEXT_STEP });
                    }}
                  />
                )}
              {state.isInsuranceInfoFilled && (
                <InsuranceInformationCompleted
                  handleClick={() => {
                    dispatch({ type: ACTION_TYPE.TOGGLE_INSURANCE_INFO });
                  }}
                />
              )}

              {state.index >= 2 && !state.isBuyersOrderFilled && (
                <BuyersOrder
                  handleClick={() => {
                    dispatch({ type: ACTION_TYPE.TOGGLE_BUYERS_ORDER });
                    dispatch({ type: ACTION_TYPE.MOVE_TO_NEXT_STEP });
                  }}
                />
              )}
              {state.isBuyersOrderFilled && (
                <BuyersOrderCompleted
                  handleClick={() => {
                    dispatch({ type: ACTION_TYPE.TOGGLE_BUYERS_ORDER });
                  }}
                />
              )}

              {state.index >= 3 && !state.isDepositFilled && (
                <Deposit
                  handleClick={() => {
                    dispatch({ type: ACTION_TYPE.TOGGLE_DEPOSIT });
                    dispatch({ type: ACTION_TYPE.MOVE_TO_NEXT_STEP });
                  }}
                />
              )}
              {state.isDepositFilled && (
                <DepositCompleted
                  hasBankDetails
                  handleClick={() => {
                    dispatch({ type: ACTION_TYPE.TOGGLE_DEPOSIT });
                  }}
                />
              )}

              <Steps financeOfferSteps />
              <p className="disclaimer text--disclaimer">
                The BMW Center Terms and Privacy Policy and BMW of North
                America, LLC (“BMW NA”) Terms and Privacy Policy apply to the
                use of this site. By using this site, you understand and agree
                that (1) this online process does not guarantee the sale or
                lease of this vehicle to you until terms are agreed and a credit
                application has been reviewed; (2) BMW NA does not sell vehicles
                nor finance or lease vehicles; (3) BMW Center sets actual price;
                (4) a vehicle may not be available for you until a BMW Center
                countersigns a Buyer’s Order and confirms both the availability
                of the vehicle and the purchase or lease offer; (5)
                circumstances, including, without limitation, pricing errors,
                vehicle damage, or unavailability, may require the termination
                or restart of any purchase or leasing transaction.
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <div className="address-right-content">
              <div className="address-right-content__inner">
                <VehicleCard
                  hasHeader
                  vehicleFinanceImg
                  cardPaymentInfo
                  hasVehicleFinancing
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <FixedVehicleCard />
    </BaseLayout>
  );
};

export default FinanceOfferPage;
