export enum Decision {
  REJECT = "reject",
  ACCEPT = "accept",
  FULL_RECIEVED = "Fully Received",
}
export enum IntegrationProvider {
  SOCURE = "Socure",
}

export enum LenderIntegrationType {
  FRAUD_IDENTIFICATION = "fraud_identification",
}

export enum Status {
  Inquiry = "Inquiry",
  AvailableInInventory = "Available In Inventory",
  InContarct = "In Contarct",
  Available = "Available",
  Draft = "Draft",
  Submitted = "Submitted",
  Approved = "Approved",
  Conditioned = "Conditioned",
  InReview = "In Review",
  Reviewed = "Reviewed",
  ReSubmitted = "Re Submitted",
  SchedulePickup = "Awaiting Scheduling", //Schedule Pickup/delivery
  Appointment = "Appointment Scheduled", //Appointment
  Cancelled = "Cancelled", //Cancelled
  Converted = "Converted", //Completed
  Completed = "Completed", //Completed
  InProgress = "In-Progress", //In-Progress
  Declined = "Declined",
  Withdrawn = "Withdrawn",
  PendingConfirmation = "Pending Confirmation",
  Expired = "Expired",
  PendingTradeInConfirmation = "Pending Trade-In Confirmation",
  Qualified = "Qualified",
  REJECTED = "Rejected",
  FULLY_RECEIVED = "Fully Received",
  TRADE_IN_INSPECTION = "Trade-In Inspection",
  WITHDRAWN_BY_CUSTOMER = "Withdrawn by Customer",
}
