import {
  Button,
  Checkbox,
  FeedbackLabel,
  Image,
  Input,
  MessageAlert,
  Popover,
  Select,
  Switch,
} from "@src/components/atoms";
import { useContext, useState } from "react";
import "./trade-in-offer.scss";
import badgeImage from "../../../../shared/assets/img/trade-in-option-badge-image.png";
import { Col, Row } from "antd";
import { AutoComplete } from "@src/components/atoms/autocomplete/autocomplete.component";
import { authContext, useAuthContext } from "@src/application/authcontext";

export const TradeInOffer = () => {
  const {
    contextState: { isauthenticated, attemptsError },
  } = useAuthContext();
  const [countState, setCountState] = useState(attemptsError);
  const [isAuthenticated, setIsAuthenticated] = useState(isauthenticated);
  const [checked, setChecked] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [transactionType, setTransactionType] = useState("");

  const [
    isDealerProvidedBonusPopoverOpen,
    setIsDealerProvidedBonusPopoverOpen,
  ] = useState(false);
  const handleDealerProvidedBonusOpenChange = (newOpen: boolean) => {
    setIsDealerProvidedBonusPopoverOpen(newOpen);
  };
  const hideDealerProvidedBonusPopup = () => {
    setIsDealerProvidedBonusPopoverOpen(false);
  };

  const [
    isAdjustedPayofAmountPopoverOpen,
    setIsAdjustedPayofAmountPopoverOpen,
  ] = useState(false);
  const handleAdjustedPayofAmountOpenChange = (newOpen: boolean) => {
    setIsAdjustedPayofAmountPopoverOpen(newOpen);
  };
  const hideAdjustedPayofAmountPopup = () => {
    setIsAdjustedPayofAmountPopoverOpen(false);
  };

  const [isEquityPopoverOpen, setIsEquityPopoverOpen] = useState(false);
  const handleEquityOpenChange = (newOpen: boolean) => {
    setIsEquityPopoverOpen(newOpen);
  };
  const hideEquityPopup = () => {
    setIsEquityPopoverOpen(false);
  };

  const [
    isEstimatedPayofAmountPopoverOpen,
    setIsEstimatedPayofAmountPopoverOpen,
  ] = useState(false);
  const handleEstimatedPayofAmountOpenChange = (newOpen: boolean) => {
    setIsEstimatedPayofAmountPopoverOpen(newOpen);
  };
  const hideEstimatedPayofAmountPopup = () => {
    setIsEstimatedPayofAmountPopoverOpen(false);
  };

  const [value_1, setValue_1] = useState<string>("");

  const staticOptions = [
    { value: "BMW Financial Services" },
    { value: "Citibank Auto Loans" },
    { value: "Chase Auto Loans" },
    { value: "Wells Fargo Auto Loans" },
    { value: "Citibank Auto Loans" },
  ];

  const handleSearch = (text: string) => {};

  const handleSelect = (data: string) => {
    setSelectedProvider(data);
  };

  const handleChange = (data: string) => {
    setValue_1(data);
  };
  let tempcheck = true;
  const submitPayoff = () => {
    setIsLoading(true);
    setTimeout(() => setIsSubmited(true), 3000);
  };
  return (
    <>
      <div className="trade-in-offer">
        <div className="trade-in-offer__title">
          <h5>Your instant cash trade-in offer is ready.</h5>
        </div>
        <div className="trade-in-offer__box trade-in-offer__box--with-badge">
          <div className="trade-in-offer__badge-image">
            <Image src={badgeImage} alt="image" aspectRatio="9:16" />
          </div>
          <div className="trade-in-offer__box__title">
            <h4>
              Kelley Blue Book Cash Offer for your <span>[20XX BMW Model]</span>
            </h4>
          </div>
          <div className="trade-in-offer__box__value">
            <div className="trade-in-offer__box__value__item">
              <div className="pricing-details__item">
                <span className="pricing-details__item__label text--body-1">
                  Total offer value
                </span>
                <span className="pricing-details__item__price text--body-1 fw-bold">
                  $X,XXX
                </span>
              </div>
              <div className="trade-in-offer__box__value__item__inner-items">
                <div className="pricing-details__item">
                  <span className="pricing-details__item__label text--body-2">
                    Cash offer value, valid until XX/XX/2024
                  </span>
                  <span className="pricing-details__item__price text--body-2">
                    $X,XXX
                  </span>
                </div>
                <div className="pricing-details__item">
                  <span className="pricing-details__item__label text--body-2">
                    Dealer-provided bonus
                    <Popover
                      trigger={["click"]}
                      placement="top"
                      onOpenChange={handleDealerProvidedBonusOpenChange}
                      open={isDealerProvidedBonusPopoverOpen}
                      closeIcon="close"
                      onClose={hideDealerProvidedBonusPopup}
                      disclaimerText="[BMW of Manhattan] has added $1,000 to your offer value."
                    >
                      <i
                        className="bmw-icon bmw-icon--after data-icon"
                        data-icon="information"
                      ></i>
                    </Popover>
                  </span>
                  <span className="pricing-details__item__price text--body-2">
                    $X,XXX
                  </span>
                </div>
              </div>
            </div>
            {transactionType === "finance" && (
              <div className="trade-in-offer__box__value__item">
                <div className="pricing-details__item">
                  <span className="pricing-details__item__label text--body-1">
                    Adjusted payoff amount
                    <Popover
                      trigger={["click"]}
                      placement="right"
                      onOpenChange={handleAdjustedPayofAmountOpenChange}
                      open={isAdjustedPayofAmountPopoverOpen}
                      closeIcon="close"
                      onClose={hideAdjustedPayofAmountPopup}
                      disclaimerText="To ensure you have the accurate payoff quote, the system adds {15} days of interest ($XXX) to the payoff amount as a buffer for potential delays in processing."
                    >
                      <i
                        className="bmw-icon bmw-icon--after data-icon"
                        data-icon="information"
                      ></i>
                    </Popover>
                  </span>
                  <span className="pricing-details__item__price text--body-1 fw-bold">
                    -$X,XXX
                  </span>
                </div>
                {!tempcheck && (
                  <div className="pricing-details__item__additional">
                    {/* Trade-in equity. Finance. Expired before credit application. */}
                    <FeedbackLabel
                      text={
                        <>
                          <span className="text--disclaimer">
                            Expired, please{" "}
                            <a
                              className="text--disclaimer link-underline"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              get a new quote
                            </a>
                          </span>
                        </>
                      }
                      type="error"
                    />
                    {/* Trade-in equity. Finance. Expired after credit application OR manual input */}
                    <FeedbackLabel
                      text={
                        <>
                          <span className="text--disclaimer">
                            Expired, please{" "}
                            <a
                              className="text--disclaimer link-underline"
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              contact dealer
                            </a>{" "}
                            to get new payoff amount
                          </span>
                        </>
                      }
                      type="error"
                    />
                  </div>
                )}
              </div>
            )}
            {/* Trade-in equity. Lease. */}
            {transactionType === "lease" && (
              <div className="trade-in-offer__box__value__item">
                <div className="pricing-details__item">
                  <span className="pricing-details__item__label text--body-1">
                    Payoff amount
                  </span>
                  <span className="pricing-details__item__price text--body-1 fw-bold">
                    -$X,XXX
                  </span>
                </div>
                <div className="pricing-details__item__additional">
                  <small className="text--disclaimer">
                    Valid until XX/XX/2024
                  </small>
                </div>
              </div>
            )}
            {/* Trade-in equity. Self Reported. */}
            {isSubmited && checked && (
              <>
                {" "}
                {!tempcheck && (
                  <div className="trade-in-offer__box__value__item">
                    <div className="pricing-details__item">
                      <span className="pricing-details__item__label text--body-1">
                        Estimated payoff amount
                        <Popover
                          trigger={["click"]}
                          placement="top"
                          onOpenChange={handleEstimatedPayofAmountOpenChange}
                          open={isEstimatedPayofAmountPopoverOpen}
                          closeIcon="close"
                          onClose={hideEstimatedPayofAmountPopup}
                          disclaimerText="This payoff amount is purely an estimate and is not binding without authentication. Dealer will verify your estimate payoff amount before credit application."
                        >
                          <i
                            className="bmw-icon bmw-icon--after data-icon"
                            data-icon="information"
                          ></i>
                        </Popover>
                      </span>
                      <span className="pricing-details__item__price text--body-1 fw-bold">
                        $X,XXX
                      </span>
                    </div>
                  </div>
                )}
                <div className="trade-in-offer__box__value__item trade-in-offer__box__value__item--total">
                  <div className="pricing-details__item">
                    <span className="pricing-details__item__label text--subsection-1">
                      Trade-in equity
                      <Popover
                        trigger={["click"]}
                        placement="bottom"
                        onOpenChange={handleEquityOpenChange}
                        open={isEquityPopoverOpen}
                        closeIcon="close"
                        onClose={hideEquityPopup}
                        disclaimerText="Trade-in equity is the value of your current vehicle that is applied toward your new purchase. Positive equity reduces the amount you finance, while negative equity may increase your loan balance. "
                      >
                        <i
                          className="bmw-icon bmw-icon--after data-icon"
                          data-icon="information"
                        ></i>
                      </Popover>
                    </span>
                    <span className="pricing-details__item__price text--subsection-1 fw-bold">
                      $X,XXX
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="trade-in-offer__box__form">
            {/* on/off switch section */}
            {!isSubmited && (
              <div className="trade-in-offer__box__form__switch">
                <span className="trade-in-offer__box__form__switch__label text--body-1">
                  Do you still owe money on your current vehicle?
                </span>
                <Switch size="small" checked={checked} onChange={setChecked} />
              </div>
            )}
            {checked && (
              <>
                {" "}
                {/* Select loan provider */}
                {isAuthenticated && !isSubmited && (
                  <div className="trade-in-offer__box__form__loan-provider">
                    <p className="trade-in-offer__box__form__discription text--body-2">
                      Please provide the following details.
                    </p>
                    <Row
                      gutter={[
                        { xs: 0, sm: 20, md: 20, lg: 24, xl: 32 },
                        { xs: 20, sm: 20, md: 20, lg: 24, xl: 32 },
                      ]}
                    >
                      {!countState && (
                        <Col xs={24} sm={24} md={24} lg={24}>
                          <AutoComplete
                            disabled={isLoading}
                            label="Finance provider"
                            showInfoIcon={false}
                            onSearch={handleSearch}
                            onSelect={handleSelect}
                            onChange={handleChange}
                            options={staticOptions}
                            placeholder="Search your finance provider"
                            filterOption={(inputValue, option) =>
                              option!.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                          />
                        </Col>
                      )}
                      {selectedProvider &&
                        selectedProvider !== "BMW Financial Services" && (
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <Select
                              disabled={isLoading}
                              id={"idFinance"}
                              onChange={(e) => setTransactionType(e)}
                              label="Transaction type"
                              options={[
                                {
                                  value: "finance",
                                  label: "Finance",
                                  disabled: false,
                                },
                                {
                                  value: "lease",
                                  label: "lease",
                                  disabled: false,
                                },
                              ]}
                            />
                          </Col>
                        )}
                      {selectedProvider &&
                        selectedProvider !== "BMW Financial Services" && (
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <Input
                              id={"idVIN"}
                              name={""}
                              label="VIN"
                              disabled={isLoading}
                            />
                          </Col>
                        )}
                      {selectedProvider && (
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <Input
                            id={"idAccountNumber"}
                            name={""}
                            label="Account number"
                            disabled={isLoading}
                          />
                        </Col>
                      )}
                      {selectedProvider === "BMW Financial Services" && (
                        <Col xs={24} sm={24} md={12} lg={12}>
                          <Input
                            id={"idLastSSN"}
                            name={""}
                            label="Last 4 digits of your SSN"
                            showInfoIcon
                            disabled={isLoading}
                          />
                        </Col>
                      )}
                      {selectedProvider && (
                        <>
                          {" "}
                          <Col xs={24} sm={24} md={24} lg={24}>
                            <Checkbox
                              id={"idHerebyChk"}
                              label="I hereby direct BMW Center to obtain pay-off information about the amounts owed on my trade-in vehicle and collect, use, and share this information in order to complete this transaction."
                              htmlFor="idHerebyChk"
                              isCompact
                              disabled={isLoading}
                            />
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={24}>
                            <div className="trade-in-offer__box__form__loan-provider__button">
                              <Button
                                htmlType={"button"}
                                text="Get payoff amount"
                                type="default"
                                onClick={submitPayoff}
                                disabled={!selectedProvider}
                              />
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                    {isLoading && (
                      <div className="trade-in-offer__box__form__loan-provider__fetching">
                        <Button htmlType={"button"} onlyIcon isLoading />
                        <p className="text--body-1">
                          Fetching your payoff amount from your finance provider
                          ...
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {/* Unauthenticated. */}
                {!isSubmited && (
                  <div className="trade-in-offer__box__form__unauthenticated">
                    {!isAuthenticated && (
                      <>
                        {" "}
                        <div className="trade-in-offer__box__form__unauthenticated__signin">
                          <div className="trade-in-offer__box__form__unauthenticated__tout">
                            <div className="trade-in-offer__box__form__unauthenticated__tout__text">
                              <i
                                className="bmw-icon bmw-icon--before data-icon"
                                data-icon="personal_data_protection"
                              ></i>
                              <p className="text--body-2">
                                Sign in to get the payoff amount. This ensures a
                                more accurate and secure way to access your
                                information.
                              </p>
                            </div>
                            <div className="trade-in-offer__box__form__unauthenticated__tout__button">
                              <Button
                                htmlType={"button"}
                                text="Sign in"
                                type="default"
                                size="small"
                              />
                            </div>
                          </div>
                          <div className="trade-in-offer__box__form__unauthenticated__divider">
                            <span className="text--body-2">OR</span>
                          </div>
                        </div>
                      </>
                    )}
                    {(!isAuthenticated || countState) && (
                      <div className="trade-in-offer__box__form__unauthenticated__without-signin">
                        <p className="trade-in-offer__box__form__discription text--body-2">
                          Estimate your payoff amount without signing in.
                        </p>
                        {isAuthenticated && countState && (
                          <div className="trade-in-offer__box__form__unauthenticated__alert">
                            <MessageAlert
                              type="error"
                              title="Sorry, you've made too many attempts. Please enter your information manually."
                            />
                          </div>
                        )}
                        <Row
                          gutter={[
                            { xs: 0, sm: 20, md: 20, lg: 24, xl: 32 },
                            { xs: 20, sm: 20, md: 24, lg: 32, xl: 40 },
                          ]}
                        >
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <Input
                              id={"idEstPayoffAmount"}
                              name={""}
                              label="Est. Payoff Amount"
                            />
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <Input
                              id={"idFinanceprovider"}
                              name={""}
                              label="Finance provider"
                            />
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <div className="trade-in-offer__box__form__unauthenticated__submit">
                              <Button
                                htmlType={"button"}
                                text="Submit"
                                type="default"
                                onClick={submitPayoff}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="trade-in-offer__contact">
          <i
            className="bmw-icon bmw-icon--after data-icon"
            data-icon="speech_bubble_faq"
          ></i>
          <p className="text--body-1">
            If you have questions for your offer, please
            <span>
              {" "}
              <a
                className="link-underline text--link-1"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                contact dealer.
              </a>
            </span>
          </p>
        </div>
      </div>
    </>
  );
};
