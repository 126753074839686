import { useNavigate } from "react-router-dom";
import {
  BundleCard,
  VehicleCard,
  FixedVehicleCard,
  InsentivesAndDiscountsDrawerFooter,
  BundleDetailsDrawerContent,
  ProductDetailsDrawerContent,
  RightRail,
  RightRailFixed,
} from "@src/components/molecules";
import "./protection-and-warranty.scss";
import {
  Button,
  IconList,
  Checkbox,
  Drawer,
  FeedbackLabel,
  Accordion,
} from "@src/components/atoms";
import { Col, Row } from "antd";
import { useState } from "react";
import { IncentivesListCard } from "@src/components/molecules/incentives-list-card/incentives-list-card.component";

import Image1 from "../../../../shared/assets/img/protection-warranty-list-img-1.png";
import Image2 from "../../../../shared/assets/img/protection-warranty-list-img-2.png";
import Image3 from "../../../../shared/assets/img/protection-warranty-list-img-3.png";
import Image5 from "../../../../shared/assets/img/protection-warranty-list-img-4.png";
import { ROUTING_PATH } from "@application/application-router";

export const ProtectionAndWarranty = () => {
  const navigate = useNavigate();
  const [isFullDetailsDrawerVisible, setFullDetailsDrawerVisible] =
    useState(false);
  const [isProductDetailsDrawerVisible, setProductDetailsDrawerVisible] =
    useState(false);

  // Function to toggle the drawer visibility
  const fullDetailstoggleDrawer = () => {
    setFullDetailsDrawerVisible(!isFullDetailsDrawerVisible);
  };

  const productDetailstoggleDrawer = () => {
    setProductDetailsDrawerVisible(!isProductDetailsDrawerVisible);
  };

  const closeDrawer = () => {
    setFullDetailsDrawerVisible(false);
    setProductDetailsDrawerVisible(false);
  };

  return (
    <>
      <div className="protection-warranty">
        <div className="bmw-container">
          <Row gutter={{ xs: 16, sm: 16, md: 24 }}>
            <Col xs={24} sm={24} md={24} lg={16}>
              <div className="incentives-discounts-left-content">
                <h3 className="protection-warranty__title">
                  <span className="text--body-2 label">Build my deal</span>
                  Vehicle accessories & add-ons
                </h3>
                <div className="protection-warranty__include-box">
                  <span className="protection-warranty__include-box__label text--label">
                    Included with this BMW:
                  </span>
                  <p className="protection-warranty__include-box__description text--body-2">
                    4-year/50,000-mile factory warranty, whichever comes first.
                  </p>
                </div>
                <div className="protection-warranty__bundle">
                  <div className="text--subsection-2">
                    Get more protection with an extended coverage bundle.
                  </div>
                  <div className="protection-warranty__bundle__grid">
                    <div className="protection-warranty__bundle__grid__box">
                      <BundleCard
                        headline={"Essential bundle"}
                        badge={"Bundle to save XX%"}
                        price={
                          <h1 className="text--stage-focus-price">
                            $40.64
                            <span className="text--subsection-1">/month</span>
                          </h1>
                        }
                        terms={
                          <div className="isFlex text--disclaimer">
                            <span>XX months</span>
                            <span>{"/"}</span>
                            <span>XXX,XXX miles</span>
                            <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                          </div>
                        }
                        details={
                          <>
                            <IconList
                              size="small"
                              listItems={[
                                {
                                  type: "checklist",
                                  text: "+1 year extended warranty",
                                },
                                {
                                  type: "checklist",
                                  text: (
                                    <div className="isFlex">
                                      Gold Coverage{" "}
                                      <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                                    </div>
                                  ),
                                },
                                {
                                  type: "checklist",
                                  text: "Guaranteed auto protection",
                                },
                                {
                                  type: "checklist",
                                  text: "Seat protection",
                                },
                              ]}
                            />
                          </>
                        }
                        footer={
                          <>
                            <Checkbox
                              id={"chkSelectBundleCard"}
                              label="Select"
                              htmlFor="chkSelectBundleCard"
                            />
                            <Button
                              htmlType={"button"}
                              text="Full details"
                              fixed
                              linkButton
                              withIcon={false}
                              size="small"
                              onClick={fullDetailstoggleDrawer}
                            />
                          </>
                        }
                      />
                    </div>
                    <div className="protection-warranty__bundle__grid__box">
                      <BundleCard
                        headline={"Comprehensive bundle"}
                        badge={"Bundle to save XX%"}
                        price={
                          <div className="text--stage-focus-price">
                            $56.01
                            <span className="text--subsection-1"> /month</span>
                          </div>
                        }
                        terms={
                          <div className="isFlex text--disclaimer">
                            <span>XX months</span>
                            <span>{"/"}</span>
                            <span>XXX,XXX miles</span>
                            <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                          </div>
                        }
                        details={
                          <>
                            <IconList
                              size="small"
                              listItems={[
                                {
                                  type: "checklist",
                                  text: "+2 year extended warranty",
                                },
                                {
                                  type: "checklist",
                                  text: (
                                    <div className="isFlex">
                                      Gold Coverage{" "}
                                      <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                                    </div>
                                  ),
                                },
                                {
                                  type: "checklist",
                                  text: "Guaranteed auto protection",
                                },
                                {
                                  type: "checklist",
                                  text: "Seat protection",
                                },
                              ]}
                            />
                          </>
                        }
                        footer={
                          <>
                            <Checkbox
                              id={"chkSelectBundleCard"}
                              label="Select"
                              htmlFor="chkSelectBundleCard"
                            />
                            <Button
                              htmlType={"button"}
                              text="Full details"
                              fixed
                              linkButton
                              withIcon={false}
                              onClick={fullDetailstoggleDrawer}
                              size="small"
                            />
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="protection-warranty-local-nav">
                  <div className="protection-warranty-local-nav__inner">
                    <i
                      className="bmw-icon bmw-icon--before bmw-icon--speech-bubble-faq data-icon"
                      data-icon="speech_bubble_faq"
                    ></i>
                    <a
                      href="/"
                      className="protection-warranty-local-nav__link text-underline text--link-2"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      Need help finding the right protection?
                    </a>
                  </div>
                </div>
                <div className="protection-warranty__list-items-title">
                  <div className="text--subsection-2">
                    Add additional protection products.
                  </div>
                </div>
                <div className="incentives-discounts--list_wrap incentives-discounts--one_grid_sm protection-products-wrap">
                  <IncentivesListCard
                    image={Image1}
                    title={"Guaranteed auto protection"}
                    termsInfo={
                      <div className="isFlex text--disclaimer">
                        <span>72 months</span>
                        <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                      </div>
                    }
                    subTitle={
                      "Lorem ipsum dolor sit amet consecte. Ultrices semper adipiscing duis vel amet."
                    }
                    price={
                      <h2 className="text--body-focus-price-1">
                        $XXX <span className="text--body-1">/month</span>
                      </h2>
                    }
                    totalPrice={"Total $X,XXX"}
                    className=""
                    hasCheckbox={
                      <Checkbox
                        id={"incentives-discounts--chk1"}
                        label="Select"
                        htmlFor="incentives-discounts--chk1"
                        isCompact
                      />
                    }
                    actionsArea={
                      <Button
                        htmlType={"button"}
                        text="Full details"
                        linkButton
                        onClick={productDetailstoggleDrawer}
                      />
                    }
                  />
                  <IncentivesListCard
                    image={Image2}
                    title={"Seat protection"}
                    termsInfo={
                      <div className="isFlex text--disclaimer">
                        <span>60 months</span>
                        <span> / </span>
                        <span>120,000 miles</span>
                        <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                      </div>
                    }
                    subTitle={
                      "Lorem ipsum dolor sit amet consecte. Ultrices semper adipiscing duis vel amet."
                    }
                    price={
                      <h2 className="text--body-focus-price-1">
                        $XXX <span className="text--body-1">/month</span>
                      </h2>
                    }
                    totalPrice={"Total $X,XXX"}
                    className=""
                    feedbackLable={
                      <FeedbackLabel
                        text={
                          "[Seat protection] is already included in [Essential bundle]"
                        }
                        type="primary"
                      />
                    }
                    hasCheckbox={
                      <Checkbox
                        id={"incentives-discounts--chk1"}
                        label="Select"
                        htmlFor="incentives-discounts--chk1"
                        isCompact
                      />
                    }
                    actionsArea={
                      <Button
                        htmlType={"button"}
                        text="Full details"
                        linkButton
                        onClick={productDetailstoggleDrawer}
                      />
                    }
                  />
                  <IncentivesListCard
                    image={Image3}
                    title={"Windshield protection"}
                    termsInfo={
                      <div className="isFlex text--disclaimer">
                        <span>48 months</span>
                        <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                      </div>
                    }
                    subTitle={
                      "Lorem ipsum dolor sit amet consecte. Ultrices semper adipiscing duis vel amet."
                    }
                    price={
                      <h2 className="text--body-focus-price-1">
                        $XXX <span className="text--body-1">/month</span>
                      </h2>
                    }
                    totalPrice={"Total $X,XXX"}
                    className=""
                    hasCheckbox={
                      <Checkbox
                        id={"incentives-discounts--chk1"}
                        label="Select"
                        htmlFor="incentives-discounts--chk1"
                        isCompact
                      />
                    }
                    actionsArea={
                      <Button
                        htmlType={"button"}
                        text="Full details"
                        linkButton
                        onClick={productDetailstoggleDrawer}
                      />
                    }
                  />
                  <IncentivesListCard
                    image={Image5}
                    title={"Paintless dent repair"}
                    termsInfo={
                      <div className="isFlex text--disclaimer">
                        <span>72 months</span>
                        <span> / </span>
                        <span>100,000 miles</span>
                        <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                      </div>
                    }
                    subTitle={
                      "Lorem ipsum dolor sit amet consecte. Ultrices semper adipiscing duis vel amet."
                    }
                    price={
                      <h2 className="text--body-focus-price-1">
                        $XXX <span className="text--body-1">/month</span>
                      </h2>
                    }
                    totalPrice={"Total $X,XXX"}
                    className=""
                    hasCheckbox={
                      <Checkbox
                        id={"incentives-discounts--chk1"}
                        label="Select"
                        htmlFor="incentives-discounts--chk1"
                        isCompact
                      />
                    }
                    actionsArea={
                      <Button
                        htmlType={"button"}
                        text="Full details"
                        linkButton
                        onClick={productDetailstoggleDrawer}
                      />
                    }
                  />
                  <div className="show-more-accordion">
                    <Accordion
                      variant="xs"
                      items={[
                        {
                          key: "1",
                          label: "Show more products (XX)",
                          children: (
                            <>
                              <IncentivesListCard
                                image={Image5}
                                title={"Paintless dent repair"}
                                termsInfo={
                                  <div className="isFlex text--disclaimer">
                                    <span>72 months</span>
                                    <span> / </span>
                                    <span>100,000 miles</span>
                                    <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                                  </div>
                                }
                                subTitle={
                                  "Lorem ipsum dolor sit amet consecte. Ultrices semper adipiscing duis vel amet."
                                }
                                price={
                                  <h2 className="text--body-focus-price-1">
                                    $XXX{" "}
                                    <span className="text--body-1">/month</span>
                                  </h2>
                                }
                                totalPrice={"Total $X,XXX"}
                                className=""
                                hasCheckbox={
                                  <Checkbox
                                    id={"incentives-discounts--chk1"}
                                    label="Select"
                                    htmlFor="incentives-discounts--chk1"
                                    isCompact
                                  />
                                }
                                actionsArea={
                                  <Button
                                    htmlType={"button"}
                                    text="Full details"
                                    linkButton
                                    onClick={productDetailstoggleDrawer}
                                  />
                                }
                              />
                              <IncentivesListCard
                                image={Image2}
                                title={"Seat protection"}
                                termsInfo={
                                  <div className="isFlex text--disclaimer">
                                    <span>60 months</span>
                                    <span> / </span>
                                    <span>120,000 miles</span>
                                    <span className="bmw-icon bmw-icon--after bmw-icon--info-icon"></span>
                                  </div>
                                }
                                subTitle={
                                  "Lorem ipsum dolor sit amet consecte. Ultrices semper adipiscing duis vel amet."
                                }
                                price={
                                  <h2 className="text--body-focus-price-1">
                                    $XXX{" "}
                                    <span className="text--body-1">/month</span>
                                  </h2>
                                }
                                totalPrice={"Total $X,XXX"}
                                className=""
                                feedbackLable={
                                  <FeedbackLabel
                                    text={
                                      "[Seat protection] is already included in [Essential bundle]"
                                    }
                                    type="primary"
                                  />
                                }
                                hasCheckbox={
                                  <Checkbox
                                    id={"incentives-discounts--chk1"}
                                    label="Select"
                                    htmlFor="incentives-discounts--chk1"
                                    isCompact
                                  />
                                }
                                actionsArea={
                                  <Button
                                    htmlType={"button"}
                                    text="Full details"
                                    linkButton
                                    onClick={productDetailstoggleDrawer}
                                  />
                                }
                              />
                            </>
                          ),
                        },
                      ]}
                      defaultActiveKey={0}
                      ghost={false}
                      expandIconPosition="start"
                    />
                  </div>
                </div>

                <div className="incentives-discounts--ctas">
                  <div className="incentives-discounts--ctas_flexible">
                    <Button
                      htmlType={"button"}
                      text="Continue"
                      type="primary"
                      fixed
                      fullWidth
                      onClick={() => {
                        navigate(`/${ROUTING_PATH.ACCESSORIES_PAGE}`);
                      }}
                    />
                    <Button
                      htmlType={"button"}
                      text="Sign in to save"
                      fullWidth
                    />
                  </div>
                  <p className="text--disclaimer text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                    proident, sunt in culpa qui officia deserunt mollit anim id
                    est laborum.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <div className="address-right-content">
                <div className="address-right-content__inner">
                  <RightRail hasFinance />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <RightRailFixed hasFinance />
      </div>
      <div className="protection-warranty-drawer">
        <Drawer
          width={336}
          open={isFullDetailsDrawerVisible}
          maskClosable={true}
          keyboard={true}
          onClose={closeDrawer}
          content={
            <>
              <BundleDetailsDrawerContent />
            </>
          }
          footer={
            <>
              <InsentivesAndDiscountsDrawerFooter
                price={
                  <>
                    <h2 className="text--body-focus-price-1">
                      $X,XXX
                      <span className="text--body-1">/month</span>{" "}
                    </h2>
                    <small className="text--disclaimer">Total: $XXXX.XX</small>
                  </>
                }
                footerActions={
                  <Button
                    htmlType={"button"}
                    text="Add to deal"
                    type="default"
                  />
                }
              />
            </>
          }
        />
        <div className="protection-warranty-product-details-drawer">
          <Drawer
            width={336}
            open={isProductDetailsDrawerVisible}
            maskClosable={true}
            keyboard={true}
            onClose={closeDrawer}
            content={
              <>
                <ProductDetailsDrawerContent />
              </>
            }
            footer={
              <>
                <InsentivesAndDiscountsDrawerFooter
                  price={
                    <>
                      <h2 className="text--body-focus-price-1">
                        $X,XXX
                        <span className="text--body-1">/month</span>{" "}
                      </h2>
                      <small className="text--disclaimer">
                        Total: $XXXX.XX
                      </small>
                    </>
                  }
                  footerActions={
                    <Button
                      htmlType={"button"}
                      text="Add to deal"
                      type="default"
                    />
                  }
                />
              </>
            }
          />
        </div>
      </div>
    </>
  );
};
